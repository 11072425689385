import { Image } from '@mantine/core'
import ImagePlaceholder from '@/assets/image-placeholder.png'

type EventThumbnailProps = {
  path: string
}

export const EventThumbnail = ({ path }: EventThumbnailProps) => {
  return (
    <Image
      h={100}
      w={150}
      fit="cover"
      src={path}
      fallbackSrc={ImagePlaceholder}
    />
  )
}
