import { Box, Card, Image, Overlay, Text } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import ImagePlaceholder from '@/assets/datasets/dataset.png'
import { SelectableCard } from '@/components/ui-shared/SelectableCard/SelectableCard'
import { DatasetType } from '@/types/dataset'
import { DatasetTypeChip } from '../DatasetTypeChip/DatasetTypeChip'
import { DatasetMenu } from './DatasetMenu'
import { FileCountLabel } from './FileCountLabel'
import { PreviewButton } from './PreviewButton'

const IMAGE_HEIGHT = 115

type DatasetCardProps = {
  name: string
  thumbnailUrl?: string
  fileCount: number
  datasetType: DatasetType
  isSelected: boolean
  onSelect: () => void
  onPreview: () => void
  onDelete: () => void
}

export const DatasetCard = ({
  name,
  thumbnailUrl,
  fileCount,
  datasetType,
  isSelected,
  onSelect,
  onPreview,
  onDelete
}: DatasetCardProps) => {
  const { hovered, ref } = useHover()

  const imageStyles = {
    root: {
      filter: isSelected ? 'none' : 'blur(1px)',
      opacity: isSelected ? 1 : 0.6
    }
  }

  return (
    <SelectableCard
      height={175}
      renderAs="div"
      isSelected={isSelected}
      onClick={onSelect}
    >
      <Card.Section ref={ref} withBorder>
        <Box pos="relative" bg="gray.0">
          <Image
            h={IMAGE_HEIGHT}
            fit="cover"
            src={thumbnailUrl || ImagePlaceholder}
            fallbackSrc={ImagePlaceholder}
            styles={imageStyles}
          />

          {hovered && (
            <Overlay color="black" backgroundOpacity={0.5} blur={50} center>
              <PreviewButton
                onClick={(e) => {
                  e.stopPropagation()
                  onPreview()
                }}
              />

              <Box pos="absolute" bottom={0} right={0}>
                <FileCountLabel fileCount={fileCount} />
              </Box>
            </Overlay>
          )}

          <Box pos="absolute" top={0} right={4}>
            <DatasetTypeChip datasetType={datasetType} />
          </Box>
        </Box>
      </Card.Section>

      <Card.Section pos="relative" pt="lg" pb="md" inheritPadding>
        <Text
          ta="center"
          size="sm"
          fw="bold"
          style={{
            userSelect: 'none'
          }}
          truncate
        >
          {name}
        </Text>

        <Box pos="absolute" top={2} right={2}>
          <DatasetMenu
            onDelete={(e) => {
              e.stopPropagation()
              onDelete()
            }}
          />
        </Box>
      </Card.Section>
    </SelectableCard>
  )
}
